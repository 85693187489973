*,
html,
body {
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
}

body {
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  overflow: auto;
  background: #d4d4d4;
}

b {
  font-weight: bold;
}
