/* -webkit-appearance: none;
  border: 0.0625rem solid ${({ theme }) => theme.colors.lightgray};
  font-size: 1rem;
  line-height: 3.125rem;
  padding: 0 1rem;
  width: 100%;
  color: ${({ theme }) => theme.colors.darkblue};
  :focus { */
/* Adding a 0.5pt outline instead of 
    changing border width so the size of 
    the element doesn't change and cause
    any jumping around */
/* border-color: rgba(14, 182, 254, 1);
    outline: 0.03125rem solid rgba(14, 182, 254, 1);
  } */

.StripeElement {
  -webkit-appearance: none;
  /* box-sizing: content-box; */
  border: 0.0625rem solid rgba(216, 216, 216, 1);
  border-radius: 1px;
  /* font-size: 1rem; */
  padding: 0.75rem 1rem;
  /* line-height doesn't seem to do anything */
  /* line-height: 2.625rem; */
  /* width: 50%; */
  /* height: 2rem; */

  /* padding: 10px 12px; */

  /* border: 1px solid transparent; */
  /* border-radius: 4px; */
  background-color: white;
  /* height: auto; */
  width: 100%;
  margin: 0;
  color: rgba(0, 48, 90, 1);
  font-weight: 400;
  font-family: "Raleway";
  /* box-shadow: 0 1px 3px 0 #e6ebf1; */

  /* -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease; */
}

/* .card-number {
  margin-bottom: 1.5rem;
} */

.card-data:first-child {
  margin-left: 0;
}

.card-data:last-child {
  margin-right: 0;
}

.card-data-container {
  display: flex;
}

.card-data-container > * {
  width: 33%;
  margin: 0 0.25rem 0;
}

/* .card-data-container {
  margin: 0 0 1rem;
  border: 1px solid red;
} */

#error ~ div,
#error ~ div > div {
  border-color: rgba(204, 18, 23, 1);
  /* outline: none; */
}

#error ~ .StripeElement--focus,
#error ~ div > .StripeElement--focus {
  border-color: rgba(14, 182, 254, 1);
}

.StripeElement--invalid {
  border-color: rgba(204, 18, 23, 1);
  color: initial;
}
.StripeElement--focus {
  border-color: rgba(14, 182, 254, 1);
  outline: 0.03125rem solid rgba(14, 182, 254, 1);
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
